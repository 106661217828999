<template>
    <app-collapse class="mb-2">
        <app-collapse-item title="Top 20 performing outlets" :isVisible="true">
            <b-card>
                <div class="h4 mb-2 d-flex justify-content-end align-items-center" style="font-weight: 900;">
               
                    <b-button v-if="$hasPermission($permissions.ExportExcel)" v-on:click="exportTopPerforminOutletsToExcel">
                        Export to excel
                    </b-button>
                </div>
            
                <vue-good-table
                    :columns="columns"
                    :rows="outletsData"
                />
            </b-card>
        </app-collapse-item>
    </app-collapse>
</template>
<script>

    import {BCard, BButton} from 'bootstrap-vue'
    import { VueGoodTable } from 'vue-good-table'
    import 'vue-good-table/dist/vue-good-table.css'
    import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
    import $permissions from '@/permissions'

    export default {
        computed: {
            $permissions() { 
                return $permissions
            }
        },
        components: {
            BCard,
            BButton,
            VueGoodTable,
            
            AppCollapse,
            AppCollapseItem
        },
        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.getTopPerformingOutelts()
            }
        },
        data() {
            return {
                outletsData:null,
                columns:[
                    {
                        label: 'Place',
                        field: 'number'
                    },
                    {
                        label: 'Outlet',
                        field: 'outlet_name'
                    },
                    {
                        label: 'Area',
                        field: 'area_name'
                    },
                    {
                        label: 'Territory',
                        field: 'territory_name'
                    },
                    {
                        label: 'District',
                        field: 'district_name'
                    },
                    {
                        label: 'Total price',
                        field: 'total_price',
                        formatFn: (data) => {
                            return data / 100
                        }

                    }
                ]
            }
        },
        methods:{

            async exportTopPerforminOutletsToExcel() {

                const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                const payload = {
                    'table': this.filterTable,
                    'dataFilterAreas': this.filterDataAreas,
                    'dataFilterTerritories': this.filterDataTerritories,
                    'dataFilterDistricts': this.filterDataDistricts
                }

                this.$http({
                    url: `/api/management/v1/report/export_top20_outlets_to_excel?date_from=${dateFromFormated}&date_to=${dateToFormated}`, //your url
                    method: 'PUT',
                    responseType: 'blob', // important
                    data: payload
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'top_20_performing_outlets.xlsx') //or any other extension
                    document.body.appendChild(link)
                    link.click()
                })

            },
            async getTopPerformingOutelts() {

                try {

                    const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                    const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                    const payload = {
                        'table': this.filterTable,
                        'dataFilterAreas': this.filterDataAreas,
                        'dataFilterTerritories': this.filterDataTerritories,
                        'dataFilterDistricts': this.filterDataDistricts
                    }

                    const data = await this.$http.put(`/api/management/v1/report/top20_outlets?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)

                    this.outletsData = data.data
                    let i = 1
                    for (const object of this.outletsData) {
                        object.number = i++
                    }
                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            }

        },
        mounted() {
            this.getTopPerformingOutelts()
        }

    }
</script>