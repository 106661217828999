<template>
    <app-collapse class="mb-2">
        <app-collapse-item title="New outlets" :isVisible="true">
            <b-card>
                <div class="h4 mb-2 d-flex justify-content-between align-items-center" style="font-weight: 900;">
                    <div>
                        Number of new outlets: {{numberOfAllOutlets}}
                    </div>
                    <b-button v-if="$hasPermission($permissions.ExportExcel)" v-on:click="exportNewOutletsToExcel">
                        Export to excel
                    </b-button>
                </div>

                <vue-good-table
                    :columns="columns"
                    :rows="outletsData"
                    :pagination-options="paginatioOptions"
                />
            </b-card>
        </app-collapse-item>
    </app-collapse>
</template>
<script>

    import {BCard, BButton} from 'bootstrap-vue'
    import { VueGoodTable } from 'vue-good-table'
    import 'vue-good-table/dist/vue-good-table.css'
    import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
    import $permissions from '@/permissions'

    export default {
        computed: {
            $permissions() { 
                return $permissions
            }
        },
        components: {
            BCard,
            BButton,
            VueGoodTable,
            AppCollapse,
            AppCollapseItem
        },
        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.newOutlets()
            }
        },
        data() {
            return {
                paginatioOptions: {
                    enabled: true,
                    mode: 'records',
                    perPage: 20,
                    position: 'top',
                    perPageDropdown: [3, 7, 9],
                    dropdownAllowAll: false,
                    setCurrentPage: 2,
                    jumpFirstOrLast : true,
                    firstLabel : 'First Page',
                    lastLabel : 'Last Page',
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'All'
                },
                outletsData:null,
                columns:[
                    {
                        label: 'Outlet',
                        field: 'outlet_name'
                    },
                    {
                        label: 'Date (First sale)',
                        field: 'time'
                    },
                    {
                        label: 'Area',
                        field: 'area_name'
                    },
                    {
                        label: 'Territory',
                        field: 'territory_name'
                    },
                    {
                        label: 'District',
                        field: 'district_name'
                    }
                ],
                numberOfAllOutlets: null
            }
        },
        methods:{
            async exportNewOutletsToExcel() {

                const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                const payload = {
                    'table': this.filterTable,
                    'dataFilterAreas': this.filterDataAreas,
                    'dataFilterTerritories': this.filterDataTerritories,
                    'dataFilterDistricts': this.filterDataDistricts
                }

                this.$http({
                    url: `/api/management/v1/report/export_new_outlets_to_excel?date_from=${dateFromFormated}&date_to=${dateToFormated}`, //your url
                    method: 'PUT',
                    responseType: 'blob', // important
                    data: payload
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'new_outlets.xlsx') //or any other extension
                    document.body.appendChild(link)
                    link.click()
                })

            },
            async newOutlets() {

                try {

                    const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                    const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                    const payload = {
                        'table': this.filterTable,
                        'dataFilterAreas': this.filterDataAreas,
                        'dataFilterTerritories': this.filterDataTerritories,
                        'dataFilterDistricts': this.filterDataDistricts
                    }

                    const data = await this.$http.put(`/api/management/v1/report/new_outlets?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)

                    if (!data.data) return

                    this.numberOfAllOutlets = data.data.length

                    this.outletsData = data.data

                    let i = 1
                    for (const object of this.outletsData) {
                        object.number = i++
                    }
                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            }

        },
        mounted() {
            this.newOutlets()
        }

    }
</script>