<template>
    <div>
        <b-card>
            <div class="d-flex mb-2">
                <div class="mr-2">
                    Date from:
                    <b-form-datepicker
                        v-model="dateFrom"
                        value-as-date
                    ></b-form-datepicker>
                </div>
            
                <div class="ml-2">
                    Date to:
                    <b-form-datepicker
                        v-model="dateTo"
                        value-as-date
                    ></b-form-datepicker>
                </div>
            </div>

            <div class="row" v-if="$hasPermission($permissions.ViewAllSales) || $hasPermission($permissions.ViewSalesForAssignedAreas)">
                <div class="col-4">
                    <div>
                        District
                    </div>
                    <v-select :options="dataDistricts" label="name" v-model="selectedDistrictFilter" @option:selected="optionAddedDistrict"  @option:deselected="optionRemovedDistrict" multiple>
                        <template #option="district">
                            {{district.name}}
                        </template>

                    </v-select>
                </div>
                <div class="col-4">
                    <div>
                        Territory
                    </div>
                    <v-select :options="territories" label="name" @option:selected="optionAddedTerritory" @option:deselected="optionRemovedTerritory" v-model="selectedTerritoryFilter" multiple>
                        <template #option="territory">
                            {{territory.name}}
                            <div style="font-size: 0.7rem;">
                                {{territory.district}}
                            </div>
                        </template>

                    </v-select>
                </div>
                <div class="col-4">
                    <div>
                        Area
                    </div>
                    <v-select :options="areas" label="name"  @option:selected="optionAddedArea" v-model="selectedAreasFilter" multiple>
                        <template #option="area">
                            {{area.name}}
                            <div style="font-size: 0.7rem;">
                                {{area.district}} -> {{area.territory}}
                            </div>
                        </template>

                    </v-select>
                </div>
                
            </div>

            <b-button @click="getNewData()" class="mt-2 btn-success">
                GO
            </b-button>

        </b-card>
        
        <sales-by-territory :dateFrom="dateFrom" :dateTo="dateTo" :newData="newData" :filterDataAreas="filterDataAreas" :filterDataTerritories="filterDataTerritories" :filterDataDistricts="filterDataDistricts" :filterTable="filterTable"></sales-by-territory>
        <new-outlets :dateFrom="dateFrom" :dateTo="dateTo" :newData="newData" :filterDataAreas="filterDataAreas" :filterDataTerritories="filterDataTerritories" :filterDataDistricts="filterDataDistricts" :filterTable="filterTable"></new-outlets>
        <total-sales :dateFrom="dateFrom" :dateTo="dateTo" :newData="newData" :filterDataAreas="filterDataAreas" :filterDataTerritories="filterDataTerritories" :filterDataDistricts="filterDataDistricts" :filterTable="filterTable"></total-sales>
        <product-total-sales :dateFrom="dateFrom" :dateTo="dateTo" :newData="newData" :filterDataAreas="filterDataAreas" :filterDataTerritories="filterDataTerritories" :filterDataDistricts="filterDataDistricts" :filterTable="filterTable"></product-total-sales>

        <app-collapse class="mb-2">
            <app-collapse-item title="Outlets info" :isVisible="true">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <total-outlets-reached :dateFrom="dateFrom" :dateTo="dateTo" :newData="newData" :filterDataAreas="filterDataAreas" :filterDataTerritories="filterDataTerritories" :filterDataDistricts="filterDataDistricts" :filterTable="filterTable"></total-outlets-reached>
                    </div>
                    <div class="col-12 col-md-6 mt-2 mt-md-0">
                        <unique-outlets-reached :dateFrom="dateFrom" :dateTo="dateTo" :newData="newData" :filterDataAreas="filterDataAreas" :filterDataTerritories="filterDataTerritories" :filterDataDistricts="filterDataDistricts" :filterTable="filterTable"></unique-outlets-reached>
                    </div>
                </div>
            </app-collapse-item>
        </app-collapse>

        <top-performing-outlets :dateFrom="dateFrom" :dateTo="dateTo" :newData="newData" :filterDataAreas="filterDataAreas" :filterDataTerritories="filterDataTerritories" :filterDataDistricts="filterDataDistricts" :filterTable="filterTable"></top-performing-outlets>
        <custom-reports></custom-reports>
    </div>
</template>

<script>
    import CustomReports from './components/Dashboard/CustomReports.vue'
    import ProductTotalSales from './components/Dashboard/ProductTotalSales.vue'
    import TotalOutletsReached from './components/Dashboard/TotalOutletsReached.vue'
    import TotalSales from './components/Dashboard/TotalSales.vue'
    import { BFormDatepicker, BCard, BButton  } from 'bootstrap-vue'
    import TopPerformingOutlets from './components/Dashboard/TopPerformingOutlets.vue'
    import SalesByTerritory from './components/Dashboard/SalesByTerritory.vue'
    import NewOutlets from './components/Dashboard/NewOutlets.vue'
    import vSelect from 'vue-select'
    import Vue from 'vue'
    import UniqueOutletsReached from './components/Dashboard/UniqueOutletsReached.vue'
    import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'


    export default {
        components: {
            TotalSales, 
            ProductTotalSales, 
            TotalOutletsReached,
            CustomReports,
            BFormDatepicker,
            TopPerformingOutlets,
            SalesByTerritory,
            NewOutlets,
            BCard,
            vSelect,
            BButton,
            UniqueOutletsReached,
            AppCollapse,
            AppCollapseItem
        },
        data() {
            return {
                checkIfAlreadyExists: {
                    'district': {},
                    'territory': {},
                    'area': {}
                },
                dateFrom: this.moment().subtract(7, 'd').toDate(),
                dateTo: new Date(),
                dataAreas: [],
                dataTerritories: [],
                dataDistricts: [],
                selectedTerritoryFilter: [],
                selectedDistrictFilter: [],
                selectedAreasFilter: [],
                filterTable: '',
                filterDataAreas: [],
                filterDataTerritories: [],
                filterDataDistricts: [],
                newData: false
            }
        },
        methods:{
            optionAddedDistrict(val) {
                const current_district = val[val.length - 1]
                this.checkIfAlreadyExists.district[current_district.id] = true
            },
            optionRemovedDistrict(removed_district) {

                const result = this.selectedTerritoryFilter.filter(ele => ele.district_id === removed_district.id)
                
                if (result) {
                    for (const territory_to_remove of result) {
                        
                        const index = this.selectedTerritoryFilter.findIndex(ele => ele.district_id === removed_district.id)
                        Vue.delete(this.selectedTerritoryFilter, index)

                        this.optionRemovedTerritory(territory_to_remove)
                    }
                }

                this.checkIfAlreadyExists.district[removed_district.id] = false
            },
            optionRemovedTerritory(territory_to_remove) {

                const result = this.selectedAreasFilter.filter(ele => ele.territory_id === territory_to_remove.id)

                if (result) {
                    for (const area_to_remove of result) {
                        const index = this.selectedAreasFilter.findIndex(ele => ele.id === area_to_remove.id)

                        Vue.delete(this.selectedAreasFilter, index)
                    }
                }

                this.checkIfAlreadyExists.territory[territory_to_remove.id] = false
            },
            optionAddedTerritory(val) {
                const current_territory = val[val.length - 1]
                this.checkIfAlreadyExists.territory[current_territory.id] = true

                if (!this.checkIfAlreadyExists.district[current_territory.district_id]) {
                    const result = this.dataDistricts.find(ele => ele.id === current_territory.district_id)
                    
                    if (result) {
                        this.selectedDistrictFilter.push(result)
                        this.checkIfAlreadyExists.district[current_territory.district_id] = true
                    }
                    
                }
            },
            optionAddedArea(val) {
                const current_area = val[val.length - 1]

                if (!this.checkIfAlreadyExists.territory[current_area.territory_id]) {
                    const result = this.dataTerritories.find(ele => ele.id === current_area.territory_id)
                    
                    if (result) {
                        this.selectedTerritoryFilter.push(result)
                        this.checkIfAlreadyExists.territory[current_area.territory_id] = true
                        this.optionAddedTerritory(this.selectedTerritoryFilter)
                    }
                    
                }
            },
            getNewData() {

                this.filterDataAreas = []
                this.filterDataTerritories = []
                this.filterDataDistricts = []
                
                for (const district of this.selectedDistrictFilter) {
                    const result_area = this.selectedAreasFilter.findIndex(ele => ele.district_id === district.id)
                    const result_territory = this.selectedTerritoryFilter.findIndex(ele => ele.district_id === district.id)

                    if (result_area === -1 && result_territory === -1) {
                        this.filterDataDistricts.push(district.id)
                    }
                }

                for (const territory of this.selectedTerritoryFilter) {
                    const result_area = this.selectedAreasFilter.findIndex(ele => ele.territory_id === territory.id)

                    if (result_area === -1) {
                        this.filterDataTerritories.push(territory.id)
                    }
                }

                for (const area of this.selectedAreasFilter) {
                    this.filterDataAreas.push(area.id)
                }
                

                //This is used as flag for all templates to tell them that data was changed. All of them have watcher on that variable if it is changed they make call to backend for updated data
                this.newData = !this.newData
            },
            async loadData() {
                const dataAreas = await this.$http.get('/api/management/v1/area')
                this.dataAreas = dataAreas.data

                const dataTerritory = await this.$http.get('/api/management/v1/territory')
                this.dataTerritories = dataTerritory.data

                const dataDistricts = await this.$http.get('/api/management/v1/district')
                this.dataDistricts = dataDistricts.data
            }
        },
        mounted() {
            this.loadData()
        },
        computed: {
            territories() {
                const tmpTerritories = []
                for (const district of this.selectedDistrictFilter) {
                    tmpTerritories.push(...this.dataTerritories.filter(d => d.district_id === district.id))
                }
                return tmpTerritories
            },
            areas() {
                const tmpAreas = []

                let selectAreasFromTerritories = []
                // if no territory is selected, then add all areas from all teritories within district to dropdown
                // if any territory is selected, then push only areas from selected territories
                if (this.selectedTerritoryFilter && this.selectedTerritoryFilter.length > 0) selectAreasFromTerritories = this.selectedTerritoryFilter
                else selectAreasFromTerritories = this.territories

                for (const territory of selectAreasFromTerritories) {
                    tmpAreas.push(...this.dataAreas.filter(a => a.territory_id === territory.id))
                }
                return tmpAreas
            }
        }
    }
</script>

<style>

</style>
