<template>
    <app-collapse class="mb-2">
        <app-collapse-item title="SKU percentage share" :isVisible="true">
            <b-card>
                <div class="d-flex justify-content-center mb-2">
                    <b-form-checkbox
                        v-for="(category,id) in categories"
                        v-model="valueChecked"
                        :value="category.id"
                        @change="getProductTotalSales()"
                        :key="'checkbox_category' + id"
                        class="ml-2"
                    >
                        {{category.name}}
                    </b-form-checkbox>
                </div>
           
                <apexchart :height="400" type="pie" :options="chartOptions" :series="graphDat"></apexchart>
            </b-card>
        </app-collapse-item>
    </app-collapse>
</template>
<script>

    import {BCard, BFormCheckbox} from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'

    export default {
        components: {
            BCard,
            apexchart: VueApexCharts,
            BFormCheckbox,
            AppCollapse,
            AppCollapseItem
        },
        props:{
            dateFrom: {
                type: Date
            },
            dateTo: {
                type: Date
            },
            filterTable:{
                type: String
            },
            filterDataAreas:{
                type: Array
            },
            filterDataTerritories:{
                type: Array
            },
            filterDataDistricts:{
                type: Array
            },
            newData: {
                type: Boolean
            }
        },
        watch:{
            newData() {
                this.getProductTotalSales()
            }
        },
        data() {
            return {
                valueChecked: [],
                categories: [],
                graphDat: [],
                chartOptions: {
                    labels: [],
                    colors:[],
                    pie: {
                        startAngle: 0,
                        endAngle: 360,
                        expandOnClick: true,
                        offsetX: 0,
                        offsetY: 0,
                        customScale: 1,
                        dataLabels: {
                            offset: 0,
                            minAngleToShowLabel: 10
                        } 
                    }
                }
            }
        },
        methods:{
            async getProductTotalSales() {

                try {

                    const dateFromFormated = this.moment(this.dateFrom).startOf('day').format('YYYY-MM-DD')
                    const dateToFormated = this.moment(this.dateTo).endOf('day').format('YYYY-MM-DD')

                    const payload = {
                        'table': this.filterTable,
                        'dataFilterAreas': this.filterDataAreas,
                        'dataFilterTerritories': this.filterDataTerritories,
                        'dataFilterDistricts': this.filterDataDistricts,
                        'categories': this.valueChecked
                    }

                    const data = await this.$http.put(`/api/management/v1/report/product_total_sales?date_from=${dateFromFormated}&date_to=${dateToFormated}`, payload)

                    if (!data.data || (data.data && data.data.length === 0)) {
                        this.graphDat = []
                        return   
                    }

                    this.graphDat = data.data[0].data

                    const tmpOptions = {...this.chartOptions}
                    this.chartOptions = tmpOptions
                    this.chartOptions.labels = data.data[0].labels
                    this.chartOptions.colors = data.data[0].color
                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            },
            async getCategories() {

                try {

                    const data = await this.$http.get('/api/management/v1/product_category/')

                    if (data.data && data.data.length === 0) return

                    this.categories = data.data

                    this.valueChecked = []
                    for (const category of data.data) {
                        this.valueChecked.push(category.id)
                    }

                } catch (err) {
                    this.$printError('Error while loading data!')
                }
            }

        },
        async mounted() {
            await this.getCategories()
            this.getProductTotalSales()
        }

    }
</script>